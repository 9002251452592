import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  Tooltip,
  IconButton,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const reportsData = [
  {
    id: 1,
    reportDate: '2024-01-01',
    commissionDue: 'KES 50,000',
    commissionPaid: 'KES 45,000',
    balance: 'KES 5,000',
  },
  {
    id: 2,
    reportDate: '2024-02-01',
    commissionDue: 'KES 60,000',
    commissionPaid: 'KES 60,000',
    balance: 'KES 0',
  },
  // Add more report records as needed
];

const CommissionReports = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGenerateReport = () => {
    const doc = new jsPDF();
    const img = new Image();
    img.src = '/images/customfit-logo.png'; // Path to your logo
  
    img.onload = function () {
      // Draw the logo
      doc.addImage(img, 'PNG', 10, 10, 50, 20); // Position and size of the logo
  
      // Set up title and details
      doc.setFontSize(20);
      doc.text('Commission Report', 105, 35, { align: 'center' }); // Centered title
  
      doc.setFontSize(12);
      doc.text('Date Range:', 20, 55); // Date range section title
      doc.text(`From: ${startDate}`, 20, 65); // Start date
      doc.text(`To: ${endDate}`, 20, 75); // End date
  
      // Generate the table
      doc.autoTable({
        startY: 85, // Start Y position of the table
        head: [['#', 'Report Date', 'Total Commission Due', 'Total Commission Paid', 'Remaining Balance']],
        body: reportsData.map((report, index) => [
          index + 1,
          report.reportDate,
          report.commissionDue,
          report.commissionPaid,
          report.balance,
        ]),
        theme: 'striped',
        headStyles: {
          fillColor: [22, 82, 130], // Header background color
          textColor: [255, 255, 255], // Header text color
          fontStyle: 'bold', // Bold header text
        },
        bodyStyles: { fontSize: 12 },
        margin: { left: 20, right: 20 },
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 30 },
          2: { cellWidth: 40 },
          3: { cellWidth: 40 },
          4: { cellWidth: 40 },
        },
      });
  
      doc.text('Thank you for your attention!', 20, doc.lastAutoTable.finalY + 10);
  
      doc.save('Commission_Report.pdf');
    };
  };
  

  const filteredReports = reportsData.filter((report) => {
    const reportDate = new Date(report.reportDate);
    return (
      (!startDate || reportDate >= new Date(startDate)) &&
      (!endDate || reportDate <= new Date(endDate))
    );
  });

  const paginatedReports = filteredReports.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className="p-4">
      <div className=" ">
       <div className="flex justify-center border-b pb-2"> 
        <h2 className="text-lg font-semibold text-gray-800"> Commission Reports</h2>
      </div>
      <div className="flex justify-end">
      <div className="flex justify-between gap-3">
        <div className="flex-1">
          <TextField
            label="Start Date"
            type="date"
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            sx={{ padding: '4px 8px', '& input': { padding: '8px' } }}  
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="flex-1">
          <TextField
            label="End Date"
            type="date"
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            sx={{ padding: '4px 8px', '& input': { padding: '8px' } }}  
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <Tooltip title="Generate Report">
          <IconButton
            onClick={handleGenerateReport}
            sx={{ color: '#1e40af' }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>

      </div>
      </div>
      </div>
     
      <TableContainer component={Paper}>
        <Table>
        <TableHead className="bg-blueCustom">
        <TableRow>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>Report Date</TableCell>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>Commission Due</TableCell>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>Commission Paid</TableCell>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>Balance</TableCell>
        </TableRow>
      </TableHead>
          <TableBody>
            {paginatedReports.map((report) => (
              <TableRow key={report.id}>
                <TableCell>{report.reportDate}</TableCell>
                <TableCell>{report.commissionDue}</TableCell>
                <TableCell>{report.commissionPaid}</TableCell>
                <TableCell>{report.balance}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredReports.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default CommissionReports;
