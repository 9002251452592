import React, { useState, useEffect } from 'react';
import { Button, Paper, TextField, FormControl, InputLabel, Select, MenuItem,CircularProgress, Stack } from '@mui/material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import QuotationPdfGenerator from './components/QuotationPdfGenerator'; // Import the PDF generation function
import CheckIcon from '@mui/icons-material/Check';
import { Box, IconButton, Typography } from '@mui/material';
const QuotationForm = ({ selectedClient, handleBackToRecords }) => {
  const [formData, setFormData] = useState({
    fullName: selectedClient?.fullName || '',
    email: selectedClient?.email || '',
    insuranceType: '',
    coverageAmount: '',
    carRegistrationNumber: '',
    propertyType: '',
    individualType: '',
    companyPolicyNumber: '',
    installments: [
      { date: '', amount: '' },
      { date: '', amount: '' },
      { date: '', amount: '' },
      { date: '', amount: '' },
      { date: '', amount: '' },
    ],
  });

  const [calculatedValues, setCalculatedValues] = useState({
    basicPremium: 0,
    tax: 0,
    stampDuty: 0,
    total: 0,
  });

  const [openeQuotationPdfGeneratorpage, setOpeneQuotationPdfGeneratorpage] = useState(false);

  useEffect(() => {
    if (formData.coverageAmount) {
      const amount = parseFloat(formData.coverageAmount.replace(/[^0-9.]/g, ''));
      if (!isNaN(amount)) {
        const basicPremium = amount; // Example calculation
        const tax = basicPremium * 0.0045; // Example calculation
        const stampDuty = 40; // Fixed stamp duty
        const total = basicPremium + tax + stampDuty;

        setCalculatedValues({
          basicPremium,
          tax,
          stampDuty,
          total,
        });
      } else {
        setCalculatedValues({
          basicPremium: 0,
          tax: 0,
          stampDuty: 0,
          total: 0,
        });
      }
    } else {
      setCalculatedValues({
        basicPremium: 0,
        tax: 0,
        stampDuty: 0,
        total: 0,
      });
    }
  }, [formData.coverageAmount]);

  const formatAmountWithCommas = (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString('en-US');
    }
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'coverageAmount') {
      const numericValue = value.replace(/[^0-9.]/g, '');
      setFormData((prevData) => ({
        ...prevData,
        [name]: formatAmountWithCommas(numericValue),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleInstallmentChange = (index, field, value) => {
    let formattedValue = value;
    if (field === 'amount') {
      formattedValue = value.replace(/[^0-9.]/g, '');
      formattedValue = formatAmountWithCommas(formattedValue);
    }

    const newInstallments = [...formData.installments];
    newInstallments[index][field] = formattedValue;
    setFormData((prevData) => ({
      ...prevData,
      installments: newInstallments,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpeneQuotationPdfGeneratorpage('loading'); // Set to 'loading' to display the loader
    setTimeout(() => {
      setOpeneQuotationPdfGeneratorpage(true); // Set to true to display the PDF generator
    }, 2000); // 2-second delay (adjust as needed)
  };
  
  return (
    <div className="w-full">
      <Button
        startIcon={<SyncAltIcon />}
        onClick={handleBackToRecords}
        variant="contained"
        sx={{ marginBottom: '16px', padding: '3px 4px', backgroundColor: '#141740' }}
      >
        Switch
      </Button>
      <Paper className="p-4">
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <TextField
                label="Client Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.fullName}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className="flex flex-col">
              <TextField
                label="Client Email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.email}
                InputProps={{ readOnly: true }}
              />
            </div>
          </div>

          <div className="flex justify-between"> 
          <FormControl fullWidth variant="outlined">

            <div className="flex flex-col">
                <InputLabel>Type of Insurance</InputLabel>
                <Select
                  name="insuranceType"
                  value={formData.insuranceType}
                  onChange={handleInputChange}
                  label="Type of Insurance"
                >
                  <MenuItem value="medical">Medical</MenuItem>
                  <MenuItem value="lastExpense">Last Expense</MenuItem>
                  <MenuItem value="motor">Motor</MenuItem>
                  <MenuItem value="property">Property</MenuItem>
                  <MenuItem value="personalAccident">Personal Accident</MenuItem>
                  <MenuItem value="wiba">WIBA</MenuItem>
                </Select>
            
            </div>
          
            </FormControl>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <TextField
                name="coverageAmount"
                label="Coverage Amount"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.coverageAmount}
                onChange={handleInputChange}
              />
             
            </div>
            <div className="flex flex-col">
              <TextField
                name="companyPolicyNumber"
                label="Company Policy Number"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.companyPolicyNumber}
                onChange={handleInputChange}
              />
            </div>
            
          </div>  



          {formData.insuranceType === 'motor' && (
            <>
              <div className="mb-4">
                <TextField
                  name="carRegistrationNumber"
                  label="Car Registration Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.carRegistrationNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                {formData.installments.map((installment, index) => (
                  <React.Fragment key={index}>
                    <div className="flex flex-col">
                      <TextField
                        label={`Installment ${index + 1} Date`}
                        type="date"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={installment.date}
                        onChange={(e) => handleInstallmentChange(index, 'date', e.target.value)}
                        sx={{ padding: '4px 8px', '& input': { padding: '8px' } }}
                      />
                    </div>
                    <div className="flex flex-col">
                      <TextField
                        label={`Installment ${index + 1} Amount`}
                        type="text"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={installment.amount}
                        onChange={(e) => handleInstallmentChange(index, 'amount', e.target.value)}
                        sx={{ padding: '4px 8px', '& input': { padding: '8px' } }}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </>
          )}

          {formData.insuranceType === 'property' && (
            <div className="mb-4">
              <TextField
                name="propertyType"
                label="Property Type"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.propertyType}
                onChange={handleInputChange}
              />
            </div>
          )}

          {formData.insuranceType === 'medical' && (
            <div className="mb-4">
              <FormControl fullWidth variant="outlined">
                <InputLabel>Health Insurance Type</InputLabel>
                <Select
                  name="individualType"
                  value={formData.individualType}
                  onChange={handleInputChange}
                  label="Health Insurance Type"
                >
                  <MenuItem value="individual">Individual</MenuItem>
                  <MenuItem value="family">Family</MenuItem>
                  <MenuItem value="corporate">Corporate/Group</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

      

         
          <div className="   shadow-lg p-4  mb-4 d "> 
            <div className="   p-2 mb-1">Basic Premium: {formatAmountWithCommas(calculatedValues.basicPremium)}</div>
            <div className="   p-2 mb-1">Tax: {formatAmountWithCommas(calculatedValues.tax)}</div>
            <div className="    p-2 mb-1">Stamp Duty: {formatAmountWithCommas(calculatedValues.stampDuty)}</div>
            <div className="    p-2 mb-1 ">Total: {formatAmountWithCommas(calculatedValues.total)}</div>
          </div>
          <div className="flex justify-center">

            
         
              {openeQuotationPdfGeneratorpage === false && (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ marginBottom: '16px', backgroundColor: '#141740' }}
                    endIcon={
                      openeQuotationPdfGeneratorpage === 'loading' && (
                        <CircularProgress color="secondary" size={24} />
                      )
                    }
                  >
                    Process Quotation
                  </Button>
                )}
                </div>


             



                {openeQuotationPdfGeneratorpage === 'loading' && (
                    <div className="flex justify-center">
                      <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                        <CircularProgress color="secondary" />
                      </Stack>
                      <h2>Processing ...</h2>
                    </div>
                  )}

                {openeQuotationPdfGeneratorpage === true && (
                  <>                  
                     <div className="flex justify-center bg-blueCustom p-3">
                      <Box
                        sx={{
                          display: 'inline-flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 40,  // Adjust size as needed
                          height: 40, // Adjust size as needed
                          borderRadius: '50%',
                          backgroundColor: '#4CAF50', // Green background
                          marginRight: 1.5, // Add space between the icon and text
                        }}
                      >
                        <CheckIcon sx={{ color: 'white' }} />
                      </Box>
                      <Typography variant="h6" sx={{ color: 'white', alignSelf: 'center' }}>
                        Posted
                      </Typography>
                    </div>
                    <div className='flex justify-center text-white bg-blueCustom ' >
                      <span >QUOTATION PDF GENERATED </span>
                    </div>

  
                  <QuotationPdfGenerator
                    formData={formData}
                    calculatedValues={calculatedValues}
                    onClose={() => setOpeneQuotationPdfGeneratorpage(false)}
                  />
                  </>


                )}

        </form>
      </Paper>
    </div>
  );
};

export default QuotationForm;
