import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  Tooltip,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const policiesData = [
  {
    id: 1,
    policyNumber: 'POL12345',
    holderName: 'John Doe',
    startDate: '2024-01-01',
    endDate: '2024-01-01',
    premium: 'KES 50,000',
    paymentSchedule: 'Monthly',
    commission: 'KES 5,000',
  },
  {
    id: 2,
    policyNumber: 'POL67890',
    holderName: 'Jane Smith',
    startDate: '2024-05-15',
    endDate: '2024-05-15',
    premium: 'KES 70,000',
    paymentSchedule: 'Quarterly',
    commission: 'KES 7,000',
  },
  // Add more policy records as needed
];

const TrackPolicies = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [startDateQuery, setStartDateQuery] = useState('');
  const [endDateQuery, setEndDateQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredPolicies = policiesData.filter((policy) => {
    const policyNumber = policy.policyNumber.toLowerCase().includes(searchQuery.toLowerCase());
    const startDateMatch = startDateQuery ? policy.startDate >= startDateQuery : true;
    const endDateMatch = endDateQuery ? policy.endDate <= endDateQuery : true;
    return policyNumber && startDateMatch && endDateMatch;
  });

  const paginatedPolicies = filteredPolicies.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className="p-4">
      <div className="  justify-between mb-4">
         <div className="flex justify-center mb-2 border-b pb-4"> 
        <h2 className="text-lg font-semibold text-gray-800 ">Insurance Policies Tracker </h2>
        </div>
        <Tooltip title="Search Policies">
          <div className="flex justify-between">
            <div className=''> 
            <TextField
              variant="outlined"
              placeholder="Search Policy No."
              value={searchQuery}
              sx={{ padding: '4px 8px', '& input': { padding: '8px' } }}  
              onChange={(e) => setSearchQuery(e.target.value)}
              
            />
             </div>
            <div> 

            <TextField
              variant="outlined"
              label="Start Date"
              type="date"
              InputLabelProps={{ 
                shrink: true,
              }}
              value={startDateQuery}
              onChange={(e) => setStartDateQuery(e.target.value)}
              sx={{ padding: '4px 8px', '& input': { padding: '8px' } }} 
            />
            <TextField
              variant="outlined"
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDateQuery}
              onChange={(e) => setEndDateQuery(e.target.value)}
              sx={{ padding: '4px 8px', '& input': { padding: '8px' } }} 
            />
            <IconButton>
              <SearchIcon sx={{ color: '#1e40af' }} />
            </IconButton>
            </div>

          </div>
        </Tooltip>
      </div>
      <TableContainer component={Paper}>
        <Table>
        <TableHead className="bg-blueCustom">
        <TableRow>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>Policy Number</TableCell>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>Holder Name</TableCell>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>Start Date</TableCell>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>End Date</TableCell>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>Premium</TableCell>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>Payment Schedule</TableCell>
          <TableCell style={{ color: 'white', padding: '8px 16px' }}>Commission</TableCell>
        </TableRow>
      </TableHead>
          <TableBody>
            {paginatedPolicies.map((policy) => (
              <TableRow key={policy.id}>
                <TableCell>{policy.policyNumber}</TableCell>
                <TableCell>{policy.holderName}</TableCell>
                <TableCell>{policy.startDate}</TableCell>
                <TableCell>{policy.endDate}</TableCell>
                <TableCell>{policy.premium}</TableCell>
                <TableCell>{policy.paymentSchedule}</TableCell>
                <TableCell>{policy.commission}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredPolicies.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TrackPolicies;
