import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import PeopleIcon from '@mui/icons-material/People';
import PolicyIcon from '@mui/icons-material/Description';
import AlarmIcon from '@mui/icons-material/Alarm';
import ReportIcon from '@mui/icons-material/Assessment';
import NoteIcon from '@mui/icons-material/Note';
import InvoiceIcon from '@mui/icons-material/Receipt';
import QuoteIcon from '@mui/icons-material/FormatQuote';
import LoginIcon from '@mui/icons-material/Login';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import WelcomeHome from './pages/welcomeHome';
import Login from './auth/Login';
import Client from './pages/Client';
import Quotation from './pages/Quotation';
import Policies from './pages/Policies';
import Reminders from './pages/Reminders';
import CommissionReports from './pages/CommissionReports';
import Claims from './pages/Claims';
const Home = () => {
  const [activeComponent, setActiveComponent] = useState(
    localStorage.getItem('activeComponent') || 'home' 
  );

  // Save the selected component to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('activeComponent', activeComponent);
  }, [activeComponent]);

  // Render the currently active component
  const renderComponent = () => {
  
    switch (activeComponent) {
      case 'home':
        return <WelcomeHome/ >;
      case 'client-details':
        return <Client/>;
      case 'track-policies':
        return  <Policies/>;
      case 'reminders':
        return <Reminders /> ;
      case 'commission-reports':
        return < CommissionReports/>;
      case 'claims':
        return <Claims />;
      case 'simple-quotes':
        return <Quotation />;
      case 'login':
        return <Login />;
      default:
        return <WelcomeHome/ >;  
    }
  };

  return (
    <div className="min-h-screen">
       <style>
        {`
          .shadow-div {
            background-color: white;
            box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.3);
            border-radius: 8px;
               
          }
        
        `}
        </style>
      <div className="py-4 pl-2">
        <img src="images/customfit-logo.png" alt="Company Logo" className="w-32" />
      </div>

      <div className="flex bg-yellowCustom items-center justify-center space-x-4 text-white py-4">
        <Tooltip title="Home">
          <Button
            onClick={() => setActiveComponent('home')}
            startIcon={<OtherHousesIcon sx={{ color: 'white' }} />}
            sx={{ color: 'white', '&:hover': { color: '#141740' } }}
          >
            Home 
          </Button>
        </Tooltip>
        <Tooltip title="Store and Manage Client Details">
          <Button
            onClick={() => setActiveComponent('client-details')}
            startIcon={<PeopleIcon sx={{ color: 'white' }} />}
            sx={{ color: 'white', '&:hover': { color: '#141740' } }}
          >
            Clients
          </Button>
        </Tooltip>
        <Tooltip title="Track Insurance Policies, Payment Schedules, and Commission Details">
          <Button
            onClick={() => setActiveComponent('track-policies')}
            startIcon={<PolicyIcon sx={{ color: 'white' }} />}
            sx={{ color: 'white', '&:hover': { color: '#141740' } }}
          >
            Policies
          </Button>
        </Tooltip>
        <Tooltip title="Generate Reminders for Policy Renewals and Payment Due Dates">
          <Button
            onClick={() => setActiveComponent('reminders')}
            startIcon={<AlarmIcon sx={{ color: 'white' }} />}
            sx={{ color: 'white', '&:hover': { color: '#141740' } }}
          >
            Reminders
          </Button>
        </Tooltip>
        <Tooltip title="Generate Reports on Commissions Due and Paid">
          <Button
            onClick={() => setActiveComponent('commission-reports')}
            startIcon={<ReportIcon sx={{ color: 'white' }} />}
            sx={{ color: 'white', '&:hover': { color: '#141740' } }}
          >
            Reports
          </Button>
        </Tooltip>
        <Tooltip title="Process Claims">
          <Button
            onClick={() => setActiveComponent('claims')}
            startIcon={<NoteIcon sx={{ color: 'white' }} />}
            sx={{ color: 'white', '&:hover': { color: '#141740' } }}
          >
            Claims
          </Button>
        </Tooltip>
        <Tooltip title="Generation of Simple Quotes">
          <Button
            onClick={() => setActiveComponent('simple-quotes')}
            startIcon={<QuoteIcon sx={{ color: 'white' }} />}
            sx={{ color: 'white', '&:hover': { color: '#141740' } }}
          >
            Quotes
          </Button>
        </Tooltip>
        <Tooltip title="Login">
          <Button
            onClick={() => setActiveComponent('login')}
            startIcon={<LoginIcon sx={{ color: 'white' }} />}
            sx={{ color: 'white', '&:hover': { color: '#141740' } }}
          >
            Login
          </Button>
        </Tooltip>
      </div>
      <main  >
        {renderComponent()}
      </main>
      
    
      
    </div>
  );
}; 

export default Home;
