import React, { useState } from 'react';
import {  TextField, Button,  Paper,  Box } from '@mui/material';
import NoteIcon from '@mui/icons-material/Note';
import jsPDF from 'jspdf';

const RiskNotes = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [riskNote, setRiskNote] = useState('');

  const handleGenerateNote = () => {
    const doc = new jsPDF();
    const img = new Image();
    img.src = '/images/customfit-logo.png'; // Path to your logo

    img.onload = function () {
      // Add the logo
      doc.addImage(img, 'PNG', 10, 10, 50, 20);

      // Set up title and details
      doc.setFontSize(20);
      doc.text('Risk Note Report', 105, 30, { align: 'center' });

      doc.setFontSize(12);
      doc.text('Date Range:', 20, 50);
      doc.text(`From: ${startDate}`, 20, 60);
      doc.text(`To: ${endDate}`, 20, 70);

      doc.setFontSize(12);
      doc.text('Risk Note:', 20, 90);
      doc.setFontSize(10);
      doc.text(riskNote, 20, 100, { maxWidth: 170 });

      doc.save('Risk_Note_Report.pdf');
    };
  };

  return (
    <Paper className="p-4">
       <div className="flex justify-center border-b pb-2"> 
      <h2 className="text-lg font-semibold text-gray-800 ">   Generate Risk Notes</h2>
      </div>
      <Box className="flex flex-col md:flex-row gap-4 mb-4">
        <TextField
          label="Start Date"
          type="date"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="End Date"
          type="date"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </Box>
      <TextField
        label="Risk Note"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        margin="normal"
        value={riskNote}
        onChange={(e) => setRiskNote(e.target.value)}
      />
      <Button
        onClick={handleGenerateNote}
        startIcon={<NoteIcon />}
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
      >
        Generate Note
      </Button>
    </Paper>
  );
};

export default RiskNotes;
