import React from 'react';

const WelcomeHome = () => {
  return (
    <div className="bg-white">
      <main className="flex flex-col items-center justify-center py-16 px-6">
        <div className="text-center max-w-xl mx-auto">
          <h1 className="text-2xl font-medium text-gray-700 mb-4 uppercase">
            Welcome to DBMS Agency Portal
          </h1>
          <p className="text-sm text-gray-500 mb-2">
            Discover the tools and features at your disposal to enhance your performance and achieve greater success.
          </p>

          <div className="py-4">
            <img src="images/customfit-logo.png" alt="Company Logo" className="w-34 mx-auto" />
          </div>
        </div>
      </main>
    </div>
  );
};

export default WelcomeHome;
