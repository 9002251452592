import React, { useState } from 'react';
import { Table, TableBody, TableCell,  TableContainer, TableHead, TableRow,  Paper,  TextField, Alert, TablePagination, } from '@mui/material';

const policies = [
  {
    id: 1,
    policyholder: 'John Doe',
    policyNumber: 'POL12345',
    renewalDate: '2024-09-10',
    paymentDueDate: '2024-09-01',
  },
  {
    id: 2,
    policyholder: 'Jane Smith',
    policyNumber: 'POL54321',
    renewalDate: '2024-9-5',
    paymentDueDate: '2024-10-05',
  },
  // Add more policies as needed
];

const Reminders = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const filteredPolicies = policies.filter((policy) => {
    const isPolicyMatch = policy.policyNumber.toLowerCase().includes(searchQuery.toLowerCase());
    const isDateMatch =
      (!startDate || new Date(policy.renewalDate) >= new Date(startDate)) &&
      (!endDate || new Date(policy.renewalDate) <= new Date(endDate));

    return isPolicyMatch && isDateMatch;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const generateReminders = (policy) => {
    const currentDate = new Date();
    const renewalDate = new Date(policy.renewalDate);
    const paymentDueDate = new Date(policy.paymentDueDate);

    const daysUntilRenewal = Math.floor((renewalDate - currentDate) / (1000 * 60 * 60 * 24));
    const daysUntilPaymentDue = Math.floor((paymentDueDate - currentDate) / (1000 * 60 * 60 * 24));

    return (
      <>
        {daysUntilRenewal <= 7 && daysUntilRenewal >= 0 && (
          <Alert severity="warning">Renewal due in {daysUntilRenewal} days.</Alert>
        )}
        {daysUntilPaymentDue <= 7 && daysUntilPaymentDue >= 0 && (
          <Alert severity="error">Payment due in {daysUntilPaymentDue} days.</Alert>
        )}
      </>
    );
  };

  const paginatedPolicies = filteredPolicies.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className="p-4">
       <div className="flex justify-center mb-2 border-b pb-4"> 
      <h2 className="text-lg font-semibold text-gray-800"> Policy Reminders</h2>
      </div>  
      <div className="flex justify-between ">
        <div  >
          <TextField
            label="Search Policy No."
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            sx={{ padding: '4px 8px', '& input': { padding: '8px' } }} // Adjust padding here
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div >
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <TextField
                label="Start Date"
                type="date"
                variant="outlined"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={startDate}
                sx={{ padding: '4px 8px', '& input': { padding: '8px' } }} // Adjust padding here
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="flex-1">
              <TextField
                label="End Date"
                type="date"
                variant="outlined"
                fullWidth
                margin="normal"
                sx={{ padding: '4px 8px', '& input': { padding: '8px' } }} // Adjust padding here
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
        <TableHead className="bg-blueCustom">
          <TableRow>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Policyholder</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Policy Number</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Renewal Date</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Payment Due Date</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Reminders</TableCell>
          </TableRow>
        </TableHead>
          <TableBody>
            {paginatedPolicies.map((policy) => (
              <TableRow key={policy.id}>
                <TableCell>{policy.policyholder}</TableCell>
                <TableCell>{policy.policyNumber}</TableCell>
                <TableCell>{policy.renewalDate}</TableCell>
                <TableCell>{policy.paymentDueDate}</TableCell>
                <TableCell>{generateReminders(policy)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-center mt-4">
        <TablePagination
          component="div"
          count={filteredPolicies.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </div>
    </div>
  );
};

export default Reminders;
