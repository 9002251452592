import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {Button } from '@mui/material';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CloseIcon from '@mui/icons-material/Close';
// Helper function to format amounts
const formatAmountWithCommas = (value) => {
  if (typeof value === 'number') {
    return value.toLocaleString('en-US');
  }
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// Function to generate PDF
const generatePDF = (formData, calculatedValues) => {
    const doc = new jsPDF();
    
    // Load the image
    const img = new Image();
    img.src = '/images/customfit-logo.png'; // Path to your logo
    
    img.onload = () => {
      doc.addImage(img, 'PNG', 10, 10, 50, 20); // x, y, width, height
    
      // Draw a horizontal line below the logo
      doc.setLineWidth(0.5);
      doc.line(10, 35, 200, 35); // x1, y1, x2, y2
  
      // Left Column: Company Information
      doc.setFontSize(12);
      doc.setFont('Helvetica', 'bold');
      doc.text('Company Name', 10, 40); // Adjusted to account for logo
      doc.setFont('Helvetica', 'normal');
      doc.text('Company Address', 10, 45); // Adjusted to account for logo
      doc.text('Phone: (123) 456-7890', 10, 50); // Adjusted to account for logo
      doc.text('Email: contact@company.com', 10, 55); // Adjusted to account for logo
  
      // Right Column: Invoice Information
      
      doc.setFontSize(12);
      doc.setFont('Helvetica', 'normal');
      doc.text(`Date: ${new Date().toLocaleDateString()}`, 200, 45, { align: 'right' });
      
      // Title for Quotation Details
    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');

    const title = 'Quotation Details';
    const pageWidth = doc.internal.pageSize.width; // PDF width
    const textWidth = doc.getTextWidth(title);
    const x = (pageWidth - textWidth) / 2; // Center X position

    doc.text(title, x, 60); // Centered text

      // Quotation Data Table
      doc.setFontSize(12);
      doc.setFont('Helvetica', 'normal');
      
      const tableColumn = ["Field", "Value"];
      const tableRows = [
        ['Client Name', formData.fullName],
        ['Client Email', formData.email],
        ['Type of Insurance', formData.insuranceType],
        ['Coverage Amount', formData.coverageAmount],
        ['Company Policy Number', formData.companyPolicyNumber],
        ...(
          formData.insuranceType === 'motor'
            ? formData.installments.flatMap((installment, index) => ([
                [`Installment ${index + 1} Date`, installment.date],
                [`Installment ${index + 1} Amount`, installment.amount]
              ]))
            : []
        ),
        ['Basic Premium', formatAmountWithCommas(calculatedValues.basicPremium)],
        ['Tax', formatAmountWithCommas(calculatedValues.tax)],
        ['Stamp Duty', formatAmountWithCommas(calculatedValues.stampDuty)],
        ['Total', formatAmountWithCommas(calculatedValues.total)],
      ];
    
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 70, // Adjust to avoid overlapping with previous content
        theme: 'striped',
        headStyles: { fillColor: [220, 220, 220] },
        styles: { fontSize: 12 },
        margin: { left: 10, right: 10 }
      });
    
      // Thank You Note
      doc.setFontSize(12);
      doc.setFont('Helvetica', 'italic');
      doc.text('Thank you for considering our services. If you have any questions or need further details, please contact us.', 10, doc.autoTable.previous.finalY + 10, { maxWidth: 180 });
    
      // Download PDF
      doc.save(`quotation-${formData.companyPolicyNumber}.pdf`);
    };
  };
  

// PDF Generator Component
const QuotationPdfGenerator = ({ formData, calculatedValues, onClose }) => {
  const handleGeneratePDF = () => {
    generatePDF(formData, calculatedValues);
    onClose(); // Close the modal or perform any other actions after generating the PDF
  };

  return (
    <div className="p-6 bg-white shadow-lg shadow-div rounded-lg">  
      <div className="mt-6 p-6 max-w-4xl mx-auto bg-white shadow-lg rounded-lg">
        <div className='flex justify-between mb-4 border-b pb-2 border-gray-300'> 
        <h3 className="text-2xl font-semibold ">Generated Quotation  </h3>
         <div className='grid-cols-2 gap-4'>
        <Button startIcon={<LocalPrintshopIcon />}   onClick={handleGeneratePDF} sx={{margin:'10px'}}   className="m-4 p-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition duration-300"  >   PDF  </Button>
        <Button  onClick={onClose}  startIcon={<CloseIcon />}   className="m-4 p-3 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition duration-300"  >Close  </Button>
        </div>
        </div>
  <div className="flex justify-between mb-6">
    <div> 
      <h4 className="text-lg font-medium">Company  CustomFit Insurance</h4>
      <p className="text-sm text-gray-600">Company Address</p>
      <p className="text-sm text-gray-600">Phone: (123) 456-7890</p>
      <p className="text-sm text-gray-600">Email: contact@company.com</p>
    </div>
    <div className="text-right">
      <p className="text-sm text-gray-600">Date: {new Date().toLocaleDateString()}</p>
    </div>
  </div>
  <div className="bg-gray-100 p-4 rounded-lg">
    <h3 className="text-xl font-semibold mb-4 border-b pb-2 border-gray-300">Quotation Details</h3>
    <table className="min-w-full divide-y divide-gray-300 border border-gray-300">
      <thead className="bg-gray-200">
        <tr>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase tracking-wider border-b">#</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase tracking-wider border-b">Amount</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-300">
        {[
          { label: 'Client Name', value: formData.fullName },
          { label: 'Client Email', value: formData.email },
          { label: 'Type of Insurance', value: formData.insuranceType },
          { label: 'Coverage Amount', value: formData.coverageAmount },
          { label: 'Company Policy Number', value: formData.companyPolicyNumber },
          ...(
            formData.insuranceType === 'motor'
              ? formData.installments.flatMap((installment, index) => ([
                  { label: `Installment ${index + 1} Date`, value: installment.date },
                  { label: `Installment ${index + 1} Amount`, value: installment.amount }
                ]))
              : []
          ),
          { label: 'Basic Premium', value: formatAmountWithCommas(calculatedValues.basicPremium) },
          { label: 'Tax', value: formatAmountWithCommas(calculatedValues.tax) },
          { label: 'Stamp Duty', value: formatAmountWithCommas(calculatedValues.stampDuty) },
          { label: 'Total', value: formatAmountWithCommas(calculatedValues.total) }
        ].map((row, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-b">{row.label}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b">{row.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="mt-6 border-t pt-4">
    <p className="text-sm text-gray-600">Thank you for considering our services. If you have any questions or need further details, please contact us.</p>
  </div>
</div>

 

    </div>
  );
};

export default QuotationPdfGenerator;
