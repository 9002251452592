import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, TablePagination, } from '@mui/material';

const clientRecords = [
  {
    id: 1,
    fullName: 'John Doe',
    phone: '+123456789',
    email: 'john.doe@example.com',
    address: '123 Main St, Cityville',
    dob: '2024-05-15',
    occupation: 'Software Engineer',
    idNumber: 'A1234567',
    companyInfo: 'Tech Corp',
  },
  {
    id: 2,
    fullName: 'Jane Smith',
    phone: '+987654321',
    email: 'jane.smith@example.com',
    address: '456 Elm St, Townsville',
    dob: '2024-08-22',
    occupation: 'Data Analyst',
    idNumber: 'B9876543',
    companyInfo: 'Data Solutions Inc.',
  },
  // Add more sample data as needed
];

const ClientRecords = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const filteredRecords = clientRecords.filter((client) => {
    const isNameMatch = client.fullName.toLowerCase().includes(searchQuery.toLowerCase());
    const isDateMatch =
      (!startDate || new Date(client.dob) >= new Date(startDate)) &&
      (!endDate || new Date(client.dob) <= new Date(endDate));

    return isNameMatch && isDateMatch;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRecords = filteredRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div className=" ">
      <div className="flex justify-between">
        <div className=" ">
          <TextField
            label="Search by Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            sx={{ padding: '4px 8px', '& input': { padding: '8px' } }} 
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className=" ">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <TextField
                label="Start Date"
                type="date"
                variant="outlined"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={startDate}
                sx={{ padding: '4px 8px', '& input': { padding: '8px' } }} 
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="flex-1">
              <TextField
                label="End Date"
                type="date"
                variant="outlined"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                sx={{ padding: '4px 8px', '& input': { padding: '8px' } }} 
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
        <TableHead className="bg-blueCustom ">
          <TableRow>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Full Name</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Phone</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Email</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Address</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Date of Birth</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Occupation</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>ID/Passport Number</TableCell>
            <TableCell style={{ color: 'white', padding: '8px 16px' }}>Company/Group Info</TableCell>
          </TableRow>
        </TableHead>
          <TableBody> 
            {paginatedRecords.map((client) => (
              <TableRow key={client.id}>
                <TableCell>{client.fullName}</TableCell>
                <TableCell>{client.phone}</TableCell>
                <TableCell>{client.email}</TableCell>
                <TableCell>{client.address}</TableCell>
                <TableCell>{client.dob}</TableCell>
                <TableCell>{client.occupation}</TableCell>
                <TableCell>{client.idNumber}</TableCell>
                <TableCell>{client.companyInfo}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRecords.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default ClientRecords;
