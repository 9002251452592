import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
 

const ClientRegister = () => {
  return (
    
      <div className="max-w-8xl mx-auto bg-white     ">
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <TextField
            fullWidth
            label="Full Name"
            variant="outlined"
            className="mb-4"
          />
          <TextField
            fullWidth
            label="Phone"
            variant="outlined"
            className="mb-4"
          />
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            className="mb-4"
          />
          <TextField
            fullWidth
            label="Address"
            variant="outlined"
            className="mb-4"
          />
          <TextField
            fullWidth
            label="Date of Birth"
            variant="outlined"
            className="mb-4"
            type="date"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            label="Occupation/Industry"
            variant="outlined"
            className="mb-4"
          />
          <TextField
            fullWidth
            label="ID/Passport Number"
            variant="outlined"
            className="mb-4"
          />
          <TextField
            fullWidth
            label="Company/Group Information (For Corporate Clients and Groups)"
            variant="outlined"
            className="mb-4"
            multiline
          />
        </div>

        <div className="flex justify-center mt-6">
          <Button style={{ color: 'white', padding: '8px 16px',backgroundColor:'#141740' }} variant="contained"    >
            Submit
          </Button>
        </div>
      </div>
   
  );
};

export default ClientRegister;
