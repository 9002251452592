import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import LockPersonIcon from '@mui/icons-material/LockPerson';

const Login = () => {
  return (
    <div className="flex items-center justify-center mt-6   ">
      <div className="bg-white p-8 rounded-lg shadow-div   w-full max-w-md">
        <h2 className="text-2xl font-bold text-yellowCustom text-center mb-6">Login</h2>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
              Email
            </label>
            <div className="flex items-center border-2   rounded-md p-2">
              <EmailIcon className="text-yellowCustom mr-2" />
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                className="w-full p-2 outline-none"
              />
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="password">
              Password
            </label>
            <div className="flex items-center border-2  rounded-md p-2">
              <LockPersonIcon className="text-yellowCustom mr-2" />
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                className="w-full p-2 outline-none"
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-yellowCustom text-white font-bold py-2 px-4 rounded hover:bg-yellow-600 transition duration-300"
          >
            Sign In
          </button> 
        </form>
      </div>
    </div>
  );
};
 
export default Login;
