import React, { useState } from 'react';
import { Button, Fade } from '@mui/material';
import ClientRegister from './ClientRegister';
import ClientRecords from './ClientRecord'; // Ensure this import matches the filename
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const Client = () => {
  const [showForm, setShowForm] = useState(false);

  const handleToggle = () => {
    setShowForm(prevShowForm => !prevShowForm);
  };

  return (
    <div className="bg-white min-h-screen py-10 px-4 sm:px-8">
      <div className="max-w-8xl mx-auto bg-white p-8 shadow-lg rounded-lg">
        <div className="flex justify-between items-center   mb-2 border-b pb-4">
          <h2 className="text-lg font-semibold text-gray-800 ">
            {showForm ? 'Client Information' : 'Client Records'}  
          </h2>
            {showForm ?  <Button variant="contained" startIcon={<RemoveRedEyeIcon />} style={{ backgroundColor: '#ffb606' }} onClick={handleToggle}>Records</Button> :
              <Button variant="contained" startIcon={<PersonAddAltIcon />} style={{ backgroundColor: '#ffb606' }} onClick={handleToggle}>Client</Button>}
          
        </div>

        <Fade in={!showForm} mountOnEnter unmountOnExit>
          <div>
            <ClientRecords />
          </div>
        </Fade>

        <Fade in={showForm} mountOnEnter unmountOnExit>
          <div>
            <ClientRegister />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Client;
